<template>
    <div class="row">
        <div class="col-md-6" v-if="mailsDestinatarios">
            <!-- Inicio Destiniatarios -->
            <companias-emails-destinatarios
            :mails="mailsDestinatarios"
            :tipos="destinatarios_tipos"
            @update="obtenerdatosmails"
            :id="id"
            />
            <!-- Fin Destiniatarios -->
        </div>
        <div class="col-md-6" v-if="mailsRemitentes">
            <!-- Inicio Remitentes -->
            <companias-emails-remitentes
            :mails="mailsRemitentes"
            :tipos="remitentes_tipos"
            @update="obtenerdatosmails"
            :id="id"
            />
            <!-- Fin Remitentes -->
        </div>
       
    </div>
</template>

<script>
import CompaniasEmailsDestinatarios from './secciones/CompaniaEmailsDestinatarios.vue'
import CompaniasEmailsRemitentes from './secciones/CompaniaEmailsRemitentes.vue'

import { PwgsApi } from '../../../../services/PwgsApi';
export default {
  components: {
      CompaniasEmailsDestinatarios,
      CompaniasEmailsRemitentes,
  },
  props:['id'],
  data() {
      return {
          mailsDestinatarios: [],
          mailsRemitentes: [],
          remitentes_tipos: [],
          destinatarios_tipos: [],
      }
  },
  methods:{
    async obtenerdatosmails(){
        const api = new PwgsApi();
        const resp = await api.get('companias/'+this.id+'/emails');
        this.destinatarios_tipos = resp.destinatarios_tipos;
        this.mailsDestinatarios = resp.destinatarios;
        this.remitentes_tipos = resp.remitentes_tipos;
        this.mailsRemitentes = resp.remitentes;
    }
  },
  mounted(){
    this.obtenerdatosmails();
},
watch:{
    id(){
        this.obtenerdatosmails();
    }
}

}
</script>