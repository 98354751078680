<template>
    <div class="row">
        <div class="col-md-8">
            <seccion-datos titulo="Datos de la compañía de seguros" @cambios="guardar" >
                <div class="form-group">
                    <label for="nombre-cia-seguros">{{ $t('general.nombre') }}</label>
                    <input type="text" class="form-control form-control-sm" id="nombre-cia-seguros" placeholder="Introduce nombre" v-model="nombre" >
                </div>
                <div class="form-group">
                    <label for="identificacion-cia-seguros">{{ $t('general.identificacion') }}</label>
                    <input type="text" class="form-control form-control-sm" id="identificacion-cia-seguros" placeholder="NIF/CIF" v-model="cif" >
                </div>  
                <div class="row"> 
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="domicilio-cia-seguros">{{ $t('general.domicilio') }}</label>
                            <input type="text" class="form-control form-control-sm" id="domicilio-cia-seguros" placeholder="Dirección" v-model="domicilio" >
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="cp-cia-seguros">{{ $t('general.cpostal') }}</label>
                            <input type="text" class="form-control form-control-sm" id="cp-cia-seguros" placeholder="CP" v-model="codigo_postal" >
                        </div>  
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="poblacion-cia-seguros">{{ $t('general.poblacion') }}</label>
                            <input type="text" class="form-control form-control-sm" id="poblacion-cia-seguros" placeholder="Población" v-model="poblacion" >
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="provincia-cia-seguros">{{ $t('general.provincia') }}</label>
                            <input type="text" class="form-control form-control-sm" id="provincia-cia-seguros" placeholder="Provincia" v-model="provincia" >
                        </div> 
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="subcuenta-cia-seguros">{{ $t('general.subcuenta') }}</label>
                            <input type="text" class="form-control form-control-sm" id="subcuenta-cia-seguros" placeholder="Subcuenta" v-model="subcuenta_companyia_asitur" >
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="subcuenta-ingresos-cia-seguros">{{ $t('general.subcuentaingresos') }}</label>
                            <input type="text" class="form-control form-control-sm" id="subcuenta-ingresos-cia-seguros" placeholder="Subcta de ingresos" v-model="subcuenta_ingresos_companyia_asitur" >
                        </div> 
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="subcuenta-varios-cia-seguros">{{ $t('general.subcuentaclivarios') }}</label>
                            <input type="text" class="form-control form-control-sm" id="subcuenta-varios-cia-seguros" placeholder="Subcta de clientes varios" v-model="subcuenta_varios_companyia_asitur" >
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="subcuenta-abono-cia-seguros">{{ $t('general.subcuentaabono') }}</label>
                            <input type="text" class="form-control form-control-sm" id="subcuenta-abono-cia-seguros" placeholder="Subcta de abonos" v-model="subcuenta_abono_companyia_asitur">
                        </div> 
                    </div>
                </div>
            </seccion-datos>
        </div>
        <div class="col-md-4">
            <!-- Inicio Contratos compañía -->
            <!-- No mostrar para insertar nueva compañía -->
            <seccion-datos titulo="Contratos" tipo="info" :botonGuardar="false" v-if="esActualizar" >
                <div class="row">                    
                    <select v-model="contratoSeleccionado" class="custom-select form-control-sm col-md-10">
                            <option value="0">{{ $t('general.seleccionecontrato')}}</option>
                            <option v-for="contrato in contratosDisponibles" :key="contrato" :value="contrato">{{ contrato }}</option>
                    </select>
                    <button class="btn btn-sm btn-light col-md-2" style="border: 1px solid grey;" @click="anadirContrato">Añadir</button>
                </div>
                <div>&nbsp;</div>
                <table class="table table-sm table-striped">
                    <tbody>
                        <tr v-for="contrato in contratos" :key="contrato.id_companyia_contrato">
                            <td >{{ contrato.contrato }} </td>
                            <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarContrato(contrato.id_companyia_contrato)">Eliminar</button></td>
                        </tr>
                    </tbody>
                </table>
            </seccion-datos>  
            <!-- Fin Contratos compañía -->          
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import $ from 'jquery'
import SeccionDatos from '../../../SeccionDatos.vue'
export default {
  components: { SeccionDatos },
    emits: ['guardar'],
    props: {
        datos: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            id_companyia_asitur: null,
            nombre: null,
            cif: null,
            domicilio: null,
            codigo_postal: null,
            poblacion: null,
            provincia: null,
            subcuenta_companyia_asitur: null,
            subcuenta_varios_companyia_asitur: null,
            subcuenta_ingresos_companyia_asitur: null,
            subcuenta_abono_companyia_asitur: null,
        }
    },
    computed: {
        esActualizar() {
            return !!this.datos;
        }
    },
    watch: {
        datos() {
            this.init();
            if(this.esActualizar){
                this.cargarContratos();
            }
        }
    },
    methods: {
        async anadirContrato(){
            if(this.contratoSeleccionado == "0"){
                return;
            }
            const api = new PwgsApi();
            const body = {"contrato": this.contratoSeleccionado};
            await api.post('companias/'+this.datos.companyias_idcompanyia+'/companias-seguros/'+this.datos.id_companyia_asitur+'/contratos', body);
            this.cargarContratos();
            this.contratoSeleccionado = "0";
        },
        async eliminarContrato(id){
            const api = new PwgsApi();
            await api.delete('companias/'+this.datos.companyias_idcompanyia+'/companias-seguros/'+this.datos.id_companyia_asitur+'/contratos/'+id);
            this.cargarContratos();
        },
        async cargarContratos(){
            const api = new PwgsApi();
            this.contratosDisponibles = await api.get('companias/'+this.datos.companyias_idcompanyia+'/contratos-disponibles');
            const resp = await api.get('companias/'+this.datos.companyias_idcompanyia+'/companias-seguros/'+this.datos.id_companyia_asitur+'/contratos');
            this.contratos = resp.datos;
        },
        init() {            
            for (let campo in this.$data) {
                this[campo] = this.datos && this.datos[campo] ? this.datos[campo] : null;
            }
            this.$data.contratos = [];
            this.$data.contratosDisponibles = [];
            this.$data.contratoSeleccionado = 0;
        },
        guardar() {
            this.$emit('guardar', this.$data,this.esActualizar);
        }
    },
    created() {
        this.init();
    },
    mounted() {
        if(this.esActualizar){
            this.cargarContratos();
        }        
        $('.select2').select2({
            theme: 'bootstrap4'
        })
    },
}
</script>