<template>
    <seccion-datos tipo="light">
      <template v-slot:header>
          <button type="button" class="btn btn-primary" @click="crearCiaSeguros">{{ $t('general.agregar') }}</button>
      </template>

      <table class="table table-striped">
          <thead>
              <th>{{ $t('general.nombre') }}</th>
              <th>CIF</th>
              <th>{{ $t('general.domicilio') }}</th>
              <th>{{ $t('general.poblacion') }}</th>
              <th></th>
          </thead>
          <tbody>
              <tr v-for="companiaSeguros in companiasSeguros"  :key="companiaSeguros.id_companyia_asitur">
                  <td>{{ companiaSeguros.nombre }}</td>
                  <td>{{ companiaSeguros.cif }}</td>
                  <td>{{ companiaSeguros.domicilio }}</td>
                  <td>{{ companiaSeguros.poblacion }}</td>
                  <td><button 
                          type="button" 
                          class="btn btn-block btn-outline-primary btn-sm" 
                          @click="editarCiaSeguros(companiaSeguros)"
                      >{{ $t('general.editar') }}
                      </button>
                      <button 
                          type="button" 
                          class="btn btn-block btn-outline-danger btn-sm" 
                          @click="eliminarCiaSeguros(companiaSeguros.id_companyia_asitur)"
                      >Eliminar
                      </button>
                  </td>
              </tr>
          </tbody>
      </table>
    </seccion-datos>

    <Dialog
      :style="{width:'1400px'}"
      :visible="esModalVisible"
      :modal="true"
      :dismissableMask="true"
      :draggable="false"
      @update:visible="cerrarModal">
      <template #header>
        <h4 class="modal-title">{{ $t('general.companiadeseguros') }}  {{ ciaSegurosEditada }}</h4>
      </template>
      <compania-seguros-datos :datos="ciaSeleccionada" @guardar="guardarCompanyia"/>
    </Dialog>
</template>
<script>
import CompaniaSegurosDatos from './CompaniaSegurosDatos.vue'
//import $ from 'jquery';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
  components: {
    'compania-seguros-datos': CompaniaSegurosDatos
  },
  props: {
      datos: {
          type: Object
      },
  },
  inject: ['guardarCompanyiaSeguros', 'eliminarCompanyiaSeguros', 'obtenerContratosCompanyiaSeguros'],
  data() {
    return {
      ciaSeleccionada: null,
      esModalVisible: false,
      companiasSeguros:[],
    }
  },
  watch:{
    datos(){
      this.cargarCiasSeguros();
    }
  },
  computed: {
    ciaSegurosEditada() {
      return this.ciaSeleccionada ? this.ciaSeleccionada.nombre : '';
    }
  },
  methods: {
    async cargarCiasSeguros(){
      const api = new PwgsApi();
      const resp = await api.get('companias/'+this.datos.idcompañia+'/companias-seguros');
      this.companiasSeguros = resp.datos;
    },
    crearCiaSeguros() {
      this.ciaSeleccionada = null;
      this.esModalVisible = true;
    },
    async editarCiaSeguros(companiaSeguros) {
      if (companiaSeguros.contratos === undefined) {
        companiaSeguros.contratos = await this.obtenerContratosCompanyiaSeguros(companiaSeguros);
      }
      this.ciaSeleccionada = companiaSeguros;
      this.esModalVisible = true;
    },
    async guardarCompanyia(datos, esActualizar) {
      if(!esActualizar){
        delete datos.contratos;
        delete datos.contratosDisponibles;
        delete datos.id_companyia_asitur;
        delete datos.contratoSeleccionado;
      }
      await this.guardarCompanyiaSeguros(datos);    
      this.esModalVisible = false;
      
    },
    eliminarCiaSeguros(idCiaSeguros) {
      if (confirm('¿Desea eliminar esta compañía de seguros?')) {
        this.eliminarCompanyiaSeguros(idCiaSeguros);
      }
    },
    cerrarModal() {
      this.esModalVisible = false;
      this.ciaSeleccionada = null;
    }
  },
  mounted() {
    this.cargarCiasSeguros();
    /*$('#modal-cia-seguros').on('show.bs.modal', function(e) {
      console.log(e);
      return true;
    });*/
  }
}
</script>