<template>
    <seccion-datos titulo="INTERPWGS" @cambios="guardar($data)" :guardando="guardando" tipo="success">
        <div class="row">
            <div class="col-md-9">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <button type="button" class="btn btn-default">{{ $t('general.generar') }}</button>
                    </div>
                    <!-- /btn-group -->
                    <input type="text" class="form-control" v-model="codigo_interpwgs_companyia">
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
export default {
    props: {
        datos: {
            type: Object
        },
        guardando: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    inject: ['guardar'],
    data() {
        return  {
            codigo_interpwgs_companyia: '',
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
        },
    },
    created() {
        this.init();
    },
}
</script>