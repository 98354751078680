<template>
    <seccion-datos
        :titulo="destinatarios"
        :botonGuardar="false"
    >
        <div v-if="mails">
            <div class="row">
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <input 
                        type="text" 
                        class="form-control " 
                        :id="'email-destinatario-cia-' + this.$route.params.id" 
                        placeholder="Email"
                        v-model="emailNuevo"
                        >
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <select 
                            class="custom-select form-control-sm" 
                            v-model="tipoNuevo"
                        >
                            <option value="">Seleccione tipo</option>
                            <option 
                                v-for="(tipo, i) in tipos" 
                                :key="'mail-destinatario-tipos-cia-' + this.$route.params.id + '-' + i"
                                :value="i"
                            >{{ tipo }}</option> 
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button 
                        class="btn btn-primary"
                        @click="agregar"
                    >{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-striped" v-if="mails">
                        <tbody>
                            <tr
                                v-for="mail in mails" 
                                :data-key="'mail-destinatario-cia-' + this.$route.params.id + '-' + mail.email" 
                                :key="'mail-destinatario-cia-' + this.$route.params.id + '-' + mail.email"
                            >
                                <td>{{ mail.email }}</td>
                                <td>{{ tipo(mail.tipo) }}</td>
                                <td>
                                    <button 
                                        type="button" 
                                        class="btn btn-block btn-outline-danger btn-xs"
                                        @click="eliminar(mail)"
                                    >{{ $t('general.eliminar') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>

<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default {
    props: {
        mails: {
            type: Array,
        },
        tipos: {
            type: Array,
        },
        id:{
            type: String,
        },
    },
    data() {
        return {
            emailNuevo: '',
            tipoNuevo: '',
            destinatarios: 'Destinatarios'
        }
    },
    methods: {
        tipo(tipo) {
            return this.tipos[tipo];
        },
        async agregar() {
            console.log('nuev', this.emailNuevo, this.tipoNuevo);
            if(this.emailNuevo === '' || this.tipoNuevo === ''){
                alert('Debe seleccionar correo y tipo.')
                return;
            }   
            const api = new PwgsApi();
            const datos = {
                email: this.emailNuevo,
                id_tipo: this.tipoNuevo,
            };
            await api.post('companias/'+this.id+'/emails', datos);
            this.$emit('update');
        },
        async eliminar(mail) {
            if (! confirm('Desea quitar este email?')) {
                return false;
            }
            const api = new PwgsApi();
            const datos = {
                correo: mail.email,
                tipo: mail.tipo,
            }
            await api.deletebody('companias/'+this.id+'/emails', datos);
            this.$emit('update');
        }
    },
    mounted() { 
        this.destinatarios = this.$t('general.destinatarios');
    }
}
</script>

<style scoped>
table {
    margin-top: 10px;
}
</style>