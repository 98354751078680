<template>
    <div class="row">
        <div class="col-md-12">
            <compania-guardias-especialidades
                :datos="datos"
            />
        </div>
    </div> 
</template>

<script>
import CompaniaGuardiasEspecialidades from './secciones/CompaniaGuardiasEspecialidades.vue'

export default {
    components: {
        CompaniaGuardiasEspecialidades,
    },
    props: {
        datos: {
            type: Object
        },
    }
}
</script>