<template>
    <seccion-datos
        :titulo="remitentes"
        :botonGuardar="false"
        tipo="info"
    >
        <div v-if="mails">
            <div class="row"  v-if="Array.isArray(correosPublicos) && correosPublicos.length>0">
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <select 
                            class="custom-select form-control-sm"
                            v-model="emailNuevo"
                            v-if="publicosready"
                        >
                            <option value="">Seleccione correo público</option>
                            <option
                            v-for="correo in correosPublicos"
                            :key="correo.id_correo"
                            :value="correo"
                            >{{ correo.nombre_correo }} ({{ correo.direccion_correo }})</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="input-group">                                                
                        <select 
                            class="custom-select form-control-sm"
                            v-model="tipoNuevo"
                        >
                            <option value="">Seleccione tipo</option>
                            <option 
                                v-for="[key, tipo] in Object.entries(tipos)" 
                                :key="key"
                                :value="key"
                            >{{ tipo }}</option> 
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button 
                        class="btn btn-primary"
                        @click="agregar"
                    >{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-striped" v-if="mails">
                        <tbody>
                            <tr
                                v-for="mail in mails" 
                                :data-key="'mail-destinatario-cia-' + this.$route.params.id + '-' + mail.email" 
                                :key="'mail-destinatario-cia-' + this.$route.params.id + '-' + mail.email"
                            >
                                <td>{{ correo(mail.id_correo_publico) }}</td>
                                <td>{{ tipo(mail.tipo) }}</td>
                                <td>
                                    <button 
                                        type="button" 
                                        class="btn btn-block btn-outline-danger btn-xs"
                                        @click="eliminar(mail)"
                                    >{{ $t('general.eliminar') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>

<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default {
    inject: ['agregarEmailCompanyia', 'eliminarEmailCompanyia'],
    props: {
        mails: {
            type: Array,
        },
        tipos: {
            type: Object,
        },
        id:{
            type: String,
        },
    },
    data() {
        return {
            correosPublicos: [],
            emailNuevo: '',
            tipoNuevo: '',
            remitentes:'Remitentes',
            publicosready:false,
        }
    },
    computed: {
        todosMails() {
            return this.mails || [];
        }
    },
    methods: {
        tipo(idTipo) {
            return this.tipos[idTipo];
        },
        correo(idcorreo){
            
            if(Array.isArray(this.correosPublicos) && this.correosPublicos.length>0){
                console.log("correottt", this.correosPublicos, idcorreo);
                var correoencontrado = this.correosPublicos.find(correo => correo.id_correo == idcorreo);
                return correoencontrado.nombre_correo;
            }
        },
        async agregar() {
            if(this.emailNuevo == '' || this.tipoNuevo == ''){
                alert('Debe seleccionar correo y tipo.')
                return;
            }   
            const api = new PwgsApi();
            const datos = {
                email: this.emailNuevo.direccion_correo,
                id_tipo: this.tipoNuevo,
                remitente:1,
                id_correo: this.emailNuevo.id_correo
            };
            await api.post('companias/'+this.id+'/emails', datos);
            this.$emit('update');
        },
        async eliminar(mail) {
            if (! confirm('Desea quitar este email?')) {
                return false;
            }
            console.log(mail);
            const api = new PwgsApi();
            const datos = {
                id_correo: mail.id_correo_publico,
                tipo: mail.tipo,
                remitente:1,
            }
            await api.deletebody('companias/'+this.id+'/emails', datos);
            this.$emit('update');
        },
        async obtenerPublicos() {
            const api = new PwgsApi();
            const response = await api.get('/correos/publicos');
            this.correosPublicos = response.datos;
            for(let correo of this.correosPublicos){
                if(!correo.id_correo){
                    this.correosPublicos=this.correosPublicos.filter(correof => correof != correo);
                }
            }
            console.log('correosfilter',this.correosPublicos);
            this.publicosready = true;
        },
    },
    mounted() {
        this.obtenerPublicos();
        this.correosPublicos = this.$store.getters['datosApp/correos'];
        console.log('corrpub',this.correosPublicos);
        this.remitentes = this.$t('general.remitentes');
    },
    watch:{
        
    }
}
</script>

<style scoped>
table {
    margin-top: 10px;
}
</style>