import { PwgsApi } from './PwgsApi.js'

export default class CompanyiasService
{
    /**
     * @private {PwgsApi}
     */
    #pwgsapi = false;

    /**
     * Constructor de la clase
     */
    constructor() {
        this.#pwgsapi = new PwgsApi();
    }

    /**
     * Todas las cías
     * @param {*} params 
     * @returns {[*]}
     */
    async getCompanyias(params) {
        return await this.#pwgsapi.get('companias', params);
    }

    /**
     * Obtiene datos de una cía
     * @param {number} id 
     * @returns {*}
     */
    async getCompanyia(id) {
        return await this.#pwgsapi.get('companias/' + id);
    }

    /**
     * Actualiza datos de una cía
     * @param {number} id 
     * @param {*} body 
     * @returns {*}
     */
    async putCompanyia(id, body) {
        return await this.#pwgsapi.put('companias/' + id, body);
    }

    /**
     * 
     * @param {*} body 
     * @returns {*}
     */
    async postCompanyia(body) {
        return await this.#pwgsapi.post('companias', body);
    }

    /**
     * Obtiene lista de las provincias asignadas a una cía
     * @param {number} id 
     * @returns {[*]}
     */
    async getProvinciasCompanyia(id) {
        return await this.#pwgsapi.get('companias/' + id + '/provincias');
    }

    /**
     * Asigna una provincia a la cía
     * @param {number} id 
     * @param {number} idprovincia 
     * @returns {*}
     */
    async postProvinciaCompanyia(id, idprovincia) {
        return await this.#pwgsapi.post('companias/' + id + '/provincias', {idprovincia});
    }

    /**
     * Elimina una provincia de la cía
     * @param {number} id 
     * @param {number} idprovincia 
     * @returns {*}
     */
    async deleteProvinciaCompanyia(id, idprovincia) {
        return await this.#pwgsapi.delete('companias/' + id + '/provincias/' + idprovincia);
    }

    /**
     * Obtiene lista de series asignadas a la cía
     * @param {number} id 
     * @returns {[*]}
     */
    async getSeriesCompanyia(id) {
        return await this.#pwgsapi.get('companias/' + id + '/lineas-facturacion');
    }

    /**
     * Añade una serie a la cía
     * @param {number} id 
     * @param {number} idSerie 
     * @returns {*}
     */
    async postSerieCompanyia(id, idSerie) {
        return await this.#pwgsapi.post('companias/' + id + '/lineas-facturacion', {id_linea: idSerie});
    }

    /**
     * Elimina una serie de la cía
     * @param {number} id 
     * @param {number} idSerie 
     * @returns {*}
     */
    async deleteSerieCompanyia(id, idSerie) {
        return await this.#pwgsapi.delete('companias/' + id + '/lineas-facturacion/' + idSerie);
    }

    /**
     * Obtiene lista de especialidades asignadas a la cía
     * @param {number} id 
     * @returns {[*]}
     */
    async getEspecialidadesCompanyia(id) {
        return await this.#pwgsapi.get('companias/' + id + '/especialidades');
    }

    /**
     * Añade una especialidad a la cía
     * @param {number} id 
     * @param {number} idEspecialidad 
     * @returns {*}
     */
    async postEspecialidadCompanyia(id, idEspecialidad) {
        return await this.#pwgsapi.post('companias/' + id + '/especialidades', {especialidades_idespecialidad: idEspecialidad});
    }

    /**
     * Actualiza campos caducidad en especialidad de la cía
     * @param {number} id 
     * @param {number} idEspecialidad 
     * @param {*} datos
     * @returns {*}
     */
    async putEspecialidadCompanyia(id, idEspecialidad, datos) {
        return await this.#pwgsapi.put('companias/' + id + '/especialidades/' + idEspecialidad, datos);
    }

    /**
     * Elimina una especialidad de la cía
     * @param {number} id 
     * @param {number} idEspecialidad 
     * @returns {*}
     */
    async deleteEspecialidadCompanyia(id, idEspecialidad) {
        return await this.#pwgsapi.delete('companias/' + id + '/especialidades/' + idEspecialidad);
    }

    /**
     * Obtiene lista de compañías de seguros asignadas a la cía actual
     * @param {number} id 
     * @returns {[*]}
     */
    async getCompanyiasSeguros(id) {
        return await this.#pwgsapi.get('companias/' + id + '/companias-seguros');
    }

    /**
     * Crea nueva compañía de seguros en la compañía
     * @param {number} id 
     * @param {{*}} datos 
     * @returns {*}
     */
    async postCompanyiaSeguros(id, datos) {
        return await this.#pwgsapi.post('companias/' + id + '/companias-seguros', datos);
    }

    /**
     * Actualiza compañía de seguros en la compañía
     * @param {number} idCia 
     * @param {number} idCiaSeguros 
     * @param {number} datos 
     * @returns {*}
     */
    async putCompanyiaSeguros(idCia, idCiaSeguros, datos) {
        return await this.#pwgsapi.put('companias/' + idCia + '/companias-seguros/' + idCiaSeguros, datos);
      
    }

    /**
     * Elimina compañía de seguros de la compañía
     * @param {number} id 
     * @param {number} idCiaSeguros 
     * @returns {*}
     */
    async deleteCompanyiaSeguros(id, idCiaSeguros) {
        return await this.#pwgsapi.delete('companias/' + id + '/companias-seguros/' + idCiaSeguros);
    }

    /**
     * Obtiene los contratos asignados a la compañía de seguros especificada, asignada a esta compañía
     * @param {number} id 
     * @param {number} idCiaSeguros 
     * @returns {*}
     */
    async getContratosCompanyiaSeguros(id, idCiaSeguros) {
        return await this.#pwgsapi.get('companias/' + id + '/companias-seguros/' + idCiaSeguros + '/contratos');
    }

    /**
     * Obtiene las especialidades donde se ha asignado porcentaje por guardia en la compañía
     * @param {number} id 
     * @returns {[*]}
     */
    async getEspecialidadesGuardias(id) {
        return await this.#pwgsapi.get('companias/' + id + '/guardias');
    }

    /**
     * Añade un incremento en una especialidad para las guardias de citas de expedientes de la compañía
     * @param {number} id 
     * @param {*} datos 
     * @returns {*}
     */
    async postEspecialidadesGuardias(id, datos) {
        return await this.#pwgsapi.post('companias/' + id + '/guardias', datos);
    }

    /**
     * Obtiene las especialidades donde se ha asignado porcentaje por guardia en la compañía
     * @param {number} id 
     * @returns {*}
     */
     async deleteEspecialidadGuardias(id, idEspecialidad) {
        return await this.#pwgsapi.delete('companias/' + id + '/guardias/' + idEspecialidad);
    }

    /**
     * Obtiene los mails de la compañía
     * @param {number} id 
     * @returns {*}
     */
    async getEmailsCompanyia(id) {
        return await this.#pwgsapi.get('companias/' + id + '/emails');
    }

    /**
     * 
     * @param {number} id 
     * @param {{*}} datos 
     * @returns {*}
     */
    async postEmailCompanyia(id, datos) {
        return await this.#pwgsapi.post('companias/' + id + '/emails', datos);
    }

    /**
     * La eliminación de emails de compañía la realizamos a través de POST, ya que nos viene mejor así
     * @param {number} id 
     * @param {{*}} datos 
     * @returns {*}
     */
    async deleteEmailCompanyia(id, datos) {
        return await this.#pwgsapi.post('companias/' + id + '/emails/eliminar', datos);
    }
}