<template>
    <div class="row">
        <div class="col-md-6">
            <!-- Inicio Tipo sistema -->
            <compania-sistema-tipo  
                v-bind="$props"
            /> 
            <!-- Fin Tipo sistema -->
        </div>

        <div class="col-md-6">
            <!-- Inicio provincias -->
            <compania-sistema-provincias v-if="mostrarProvincias" v-bind="$props" />
            <!-- Fin provincias -->

            <!-- Inicio contratos-->
            <compania-sistema-contratos v-bind="$props" />
            <!-- Fin contratos -->

            <!-- Inicio INTERPWGS -->
            <compania-sistema-interpwgs v-bind="$props"/>
            <!-- Fin INTERPWGS -->
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi'
import CompaniaSistemaTipo from './secciones/CompaniaSistemaTipo.vue'
import CompaniaSistemaProvincias from './secciones/CompaniaSistemaProvincias.vue'
import CompaniaSistemaContratos from './secciones/CompaniaSistemaContratos.vue'
import CompaniaSistemaInterpwgs from './secciones/CompaniaSistemaInterpwgs.vue'
export default {
    components: {
        CompaniaSistemaTipo,
        CompaniaSistemaProvincias,
        CompaniaSistemaInterpwgs,
        CompaniaSistemaContratos,
    },
    data(){
        return{
            mostrarProvincias:false,
        }
    },
    props: {
        datos: {
            type: Object
        },
    },
    methods: {
        async comprobarProvinciasActivado(){
            const api = new PwgsApi();
            console.log('datsss', this.datos);
            const compr1 = await api.get('parametros-pwgs/distribucion_expedientes_provincia');
            if(compr1.distribucion_expedientes_provincia==1 && this.datos.sistema_provincias==1)this.mostrarProvincias = true;                
        }
    },
    mounted() {
        this.comprobarProvinciasActivado();
    }
}
</script>