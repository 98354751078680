<template>
    <seccion-datos :titulo="especialidadesdeguardia" :botonGuardar="false">
        <form class="form form-inline">
            <div class="form-group">
               <!----> <select class="custom-select form-control-sm select2 especialidades-guardias">
                    <option value="">{{$t('general.seleccioneespecialidad')}}</option>
                    <option 
                        v-for="especialidad in especialidades"
                        :key="'cia-guardia-' + $route.params.id + '-' + especialidad.id"
                        :value="especialidad.id"
                    >{{ especialidad.nombre }}</option>
                </select>
            </div>
            <input 
                type="number" 
                class="form-control " 
                title="Porcentaje Incremento precio concepto" 
                :placeholder="descripcionplaceholder"
                v-model="incremento"
            >
            <button type="button" class="btn btn-success" @click="agregar">{{$t('general.anadir')}}</button>
        </form>

        <table class="table" v-if="datos && datos.especialidadesGuardias">
            <thead>
                <tr>
                    <th>{{$t('general.especialidad')}}</th>
                    <th>{{$t('general.incremento')}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                
                    <tr
                    v-for="especialidadGuardia in datos.especialidadesGuardias"
                    :key="'esp-guardia-asignada-' + $route.params.id + '-' + especialidadGuardia.id_companyia_incremento"
                >
                   <!-- <td width="80%">{{ nombreEspecialidadGuardia(especialidadGuardia.id_especialidad) }}</td>-->
                    <td width="80%">{{ especialidadGuardia.nombre }}</td>
                    <td>{{ especialidadGuardia.incremento }}%</td>
                    <td>
                        <button 
                            type="button" 
                            class="btn btn-block btn-outline-danger btn-xs" 
                            @click="eliminar(especialidadGuardia.id_especialidad)"
                        >{{$t('general.eliminar')}}</button>
                    </td>
                </tr>
                
            </tbody>
        </table>
    </seccion-datos>
</template>

<script>
import $ from 'jquery'


export default {
    inject: ['agregarEspecialidadGuardias', 'eliminarEspecialidadGuardias'],
    props: {
        datos: {
            type: Object,
        }
    },
    data() {
        return {
            especialidadesGuardias: [],
            especialidades: [],
            incremento: '',
            especialidadSeleccionada: '',
            especialidadesdeguardia: 'Especialidades de Guardia',
            descripcionplaceholder: "Introducir % incremento",
        }
    },
    computed: {
        especialidadesNoAsignadas() {
            if (this.especialidades && this.especialidades.length > 0) {
                const especialidades = {... this.especialidades};
                return especialidades.filter(esp => {
                    for (let especialidadAsignada of this.especialidadesGuardias) {
                        if (especialidadAsignada.id_especialidad === esp.id) {
                            return false;
                        }
                    }
                    return true;
                });
            }
            return [];
        }
    },
    methods: {
        nombreEspecialidadGuardia(idEspecialidad) {
            let espe = this.especialidades.find(esp => esp.id === idEspecialidad);
            return espe.nombre;
        },
        async agregar() {
            const especialidadSeleccionada = this.especialidadSeleccionada;
            const incremento = this.incremento;
            if (incremento !== '' && 
                    +incremento > 0 && 
                    especialidadSeleccionada !== '') {
                return await this.agregarEspecialidadGuardias(especialidadSeleccionada, incremento);
            }
        },
        async eliminar(idEspecialidad) {
            
            const res = await this.eliminarEspecialidadGuardias(idEspecialidad);
            this.init();
            return res;
        },
       
    },
    async created() {
        this.especialidades = await this.$store.getters['especialidades/especialidades'];
    },
    mounted() {
        const $select = $('.select2.especialidades-guardias');
        $select.select2({
            theme: 'bootstrap4'
        });
        $select.on('select2:select', (e) => {
            var data = e.params.data;
            this.especialidadSeleccionada = +data.id;
        });
        this.especialidadesdeguardia = this.$t('general.especialidadesdeguardia');
        this.descripcionplaceholder = this.$t('general.descripcionplaceholder');
    },
    
}
</script>