<template>
    <div class="col-md-4">
        <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">
                            <!-- Inicio sla concertar cita -->
                            <div class="card card-danger card-outline">                            
                                <div class="card-body ">
                                    <h6>{{ $t('general.concertadaprimeracita') }}</h6>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.limite') }}</span>
                                        </div>
                                        <input type="number" class="form-control" placeholder="hh">
                                        <input type="number" class="form-control" placeholder="dd" >
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                        </div>
                                        <input type="number" class="form-control" placeholder="hh">
                                        <input type="number" class="form-control" placeholder="dd" disabled>
                                    </div>
                                </div>
                            </div>
                            <!-- Fin sla concertar cita -->
                            <!-- Inicio sla máxima 1ª cita -->
                            <div class="card card-warning card-outline">                            
                                <div class="card-body ">
                                    <h6>{{ $t('general.fechamax') }}</h6>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.limite') }}</span>
                                        </div>
                                        <input type="number" class="form-control" placeholder="hh">
                                        <input type="number" class="form-control" placeholder="dd" disabled>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                        </div>
                                        <input type="number" class="form-control" placeholder="hh">
                                        <input type="number" class="form-control" placeholder="dd" disabled>
                                    </div>
                                </div>
                            </div>
                            <!-- Fin sla máxima 1ª cita -->
                            <!-- Inicio sla Finalización -->
                            <div class="card card-olive card-outline">                            
                                <div class="card-body ">
                                    <h6>{{ $t('general.finalizacion') }}</h6>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.limite') }}</span>
                                        </div>
                                        <input type="number" class="form-control" placeholder="hh">
                                        <input type="number" class="form-control" placeholder="dd" disabled>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.aviso') }}</span>
                                        </div>
                                        <input type="number" class="form-control" placeholder="hh">
                                        <input type="number" class="form-control" placeholder="dd" disabled>
                                    </div>
                                </div>
                            </div>
                            <!-- Fin sla máxima 1ª cita -->
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{ $t('general.tiposdedano') }}</label>
                                <select class="select2" multiple="multiple" data-placeholder="Select a State" style="width: 100%;">
                                    <option>AGUA</option>
                                    <option>ASISTENCIA</option>
                                    <option>CONEXIÓN</option>
                                    <option>VIENTO</option>
                                    <option>...</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button class="btn btn-sm btn-success">{{ $t('general.guardar') }}</button>
                    <button class="btn btn-sm btn-danger">{{ $t('general.eliminar') }}</button>
                </div>
        </div>
    </div>
</template>
<script>


</script>