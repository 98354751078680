<template>
    <seccion-datos titulo="Provincias" :botonGuardar="false" tipo="info">
        <div class="row" style="gap: 10px;">
            <div class="form-group" style="margin: 0;">
                <label>{{ $t('general.integrarprovincias') }}</label>
                <select v-model="provinciaSelec" class="custom-select form-control-sm select2 provincias">
                    <option value="">{{ $t('general.seleccioneprovincias') }}</option>
                    <option :value="provincia.id_provincia" v-for="provincia in provinciasDisponibles" :key="provincia.id_provincia">{{ provincia.nombre_provincia }}</option>
                </select>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: end;">
                <button class="btn btn-primary" @click="agregar">{{ $t('general.agregar') }}</button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-8">
                <table class="table table-sm table-striped">
                    <tbody>
                        <tr v-for="provincia in provinciasAsignadas" :key="provincia.id_provincia">
                            <td width="80%">{{ provincia.nombre_provincia }}</td>
                            <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminar(provincia.id_companyia_provincia)">{{ $t('general.eliminar') }}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: {
        datos: {
            type: Object
        },
    },
    data() {
        return  {
            provinciasAsignadas: [],
            provinciasDisponibles:[],
            provinciaSelec:'',
        }
    },
    methods: {
        async comprobarProvincias() {
            this.provinciaSelec = '';
            const api = new PwgsApi();
            const provs = await api.get('/provincias');
            const todasProvincias = provs.datos;
            const resp = await api.get('companias/'+this.datos.idcompañia+'/provincias');
            console.log('resp', resp);
            this.provinciasAsignadas = resp.datos;
            var ids = [];
            console.log("todasProvincias", todasProvincias, this.provinciasAsignadas);
            if (this.provinciasAsignadas){
                for(let asi of this.provinciasAsignadas) {
                    ids.push(asi.id_provincia);
                }
                console.log("todasProvincias", this.todasProvincias, ids);
                this.provinciasDisponibles = todasProvincias.filter(provincia => !ids.includes(provincia.id_provincia));
            }else{
                this.provinciasDisponibles = todasProvincias;
            }
        },
        async agregar() {
            if(this.provinciaSelec == ''){
                alert('Seleccione una provincia');
                return;
            }else{
                const api = new PwgsApi();
                await api.post('companias/'+this.datos.idcompañia+'/provincias', {idprovincia: this.provinciaSelec});
                this.comprobarProvincias();
            }
        },
        async eliminar(idProvincia) {
            const api = new PwgsApi();
            await api.delete('companias/'+this.datos.idcompañia+'/provincias/'+idProvincia);
            this.comprobarProvincias();
        }
    },
    mounted() {
        this.comprobarProvincias();
    },
}
</script>