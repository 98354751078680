<template>
     <div class="row">
        <div class="col-md-3">
            <!-- Inicio Teléfonos -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.telefonos') }}</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <input type="number" class="form-control form-control-sm" id="telefono-centralita" placeholder="">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-primary btn-sm">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <td width="80%">963559516</td>
                                <td><button type="button" class="btn btn-block btn-outline-danger btn-xs">{{ $t('general.eliminar') }}</button></td>
                            </tr>
                            <tr>
                                <td width="80%">963559412</td>
                                <td><button type="button" class="btn btn-block btn-outline-danger btn-xs">{{ $t('general.eliminar') }}</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Fin Teléfonos -->
        </div>
        <div class="col-md-3">
            <!-- Inicio rutina -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.rutina') }}</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="rutina-centralita">{{ $t('general.nombredelarutina') }}</label>
                        <input type="text" class="form-control form-control-sm" id="rutina-centralita" placeholder="">
                    </div> 
                    <div class="alert alert-default alert-dismissible">
                        <i class="icon fas fa-info"></i>
                    {{ $t('general.descrutina') }}
                    </div>
                </div>
            </div>
            <!-- Fin Rutina -->
        </div>
        <div class="col-md-3">
            <!-- Inicio prefijo -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.prefijo') }}</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="prefijo-centralita">{{ $t('general.numerodeprefijo') }}</label>
                        <input type="text" class="form-control form-control-sm" id="prefijo-centralita" placeholder="">
                    </div> 
                    <div class="alert alert-default alert-dismissible">
                        <i class="icon fas fa-info"></i>
                        {{ $t('general.descprefijo') }}
                    </div>
                </div>
            </div>
            <!-- Fin prefijo -->
        </div>
     </div>

</template>