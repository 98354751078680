<template>
    <div class="col-md-4">
        <div class="card card-widget widget-user-2">
            <div class="widget-user-header bg-warning">
                <div class="widget-user-image">
                    <img class="img-circle elevation-2"
                        src="https://cdn.icon-icons.com/icons2/2483/PNG/512/user_icon_149851.png" alt="User Avatar">
                </div>
                <h3 class="widget-user-username">{{tramitador.nombre}}</h3>
                <div class="widget-user-desc col-md-6">
                    <button type="button" class="btn btn-block btn-outline-danger btn-xs"
                        @click="eliminarTramitador(id, tramitador.idusuario)">{{ $t('general.quitardecompania')
                        }}</button>
                </div>
            </div>
            <div class="card-footer p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            {{ $t('general.expedientesactivos') }} <span
                                class="float-right badge bg-primary">{{tramitador.activos_total}}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            {{ $t('general.expedientescompania') }} <span
                                class="float-right badge bg-warning">{{tramitador.activos_cia}}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" @click="openBasic()">
                            {{ $t('general.companias') }} <span
                                class="float-right badge bg-info">{{tramitador.companias_asignadas.length}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="displayBasic" :modal="true">
        <template #header>
            <h3>{{ $t('general.companiasasignadasa') }} {{tramitador.nombre}}</h3>
        </template>
        <table>
            <tbody>
                <div v-for="compania in companiasTramitadores" :key="compania.id">
                    <tr>
                        <td>{{compania.nombre}}</td>
                    </tr>
                </div>
            </tbody>
        </table>
    </Dialog>
</template>
<script> 
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    props: ['tramitador','id'],
    data() {
        return {
            Seleccionado: '', 
            companiasTramitadores: [],
            displayBasic: false,
        }
    },
    methods: {
        async cargaTramitadores() {
            /* get  modulos/pwgsapi/index.php/companias/:id */
            const api = new PwgsApi;
            let aux = '';
            for (var i = 0; i <= this.tramitador.companias_asignadas.length; i++) {
                aux = await api.get('companias/' + this.tramitador.companias_asignadas[i]);
                this.companiasTramitadores[i] = aux.datos;

            } 
        },
        openBasic(){
            this.displayBasic = true;
            this.cargaTramitadores();
        },
        async eliminarTramitador(idcomp,idtram) { 
            /* delete  modulos/pwgsapi/index.php/companias/:id/tramitadores/:id_tramitador */
            const api = new PwgsApi;
            await api.delete('companias/' + idcomp + '/tramitadores/' + idtram);
            this.$parent.listaTramitadores(this.id);
        },

    },
    mounted() {

    },
}
</script>

