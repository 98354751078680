<template>
    <div class="row">
        <compania-slas-dano />
        <div class="col-md-2">
            <button class="btn btn-primary">{{ $t('general.crear') }}</button>
        </div>
    </div>
</template>
<script>
import CompaniaSlasDano from './CompaniaSlasDano.vue'
export default {
  components: {
    'compania-slas-dano': CompaniaSlasDano
  }
}
</script>
