<template>
    <seccion-datos :titulo="provs" :botonGuardar="false" tipo="warning">
        <div class="row" style="column-gap: 15px;">
            <div class="col-md-8">                
                <label>Añadir contratos a la compañía</label>
                <select style="width: 100%;" class="custom-select form-control-sm" v-model="contratoSeleccionado">
                    <option value="0">Selecciona un contrato</option>
                    <option :value="contrato" v-for="contrato in contratosDisponibles" :key="contrato">{{ contrato }}</option>
                </select>
            </div>
            <div class="col-md-2" style="display: grid; align-items: end;"><button class="btn btn-light" style="width:fit-content; border: 1px solid grey;" @click="anadirContrato">Añadir</button></div>    
        </div>
        <br>
        <div class="row">
            <div class="col-md-8">
                <table class="table  table-sm table-striped">
                    <tbody>
                        <tr v-for="[id, nombre] in Object.entries(contratos)" :key="id">
                            <td width="80%">{{ nombre }}</td>
                            <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarContrato(id)">{{ $t('general.eliminar') }}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: {
        datos: {
            type: Object
        },
    },
    data() {
        return  {
            contratoSeleccionado:0,
            contratosDisponibles: [],
            contratos:[],
            provs:'Contratos',
        }
    },
    mounted(){
        this.cargarContratos();
    },
    watch: {
        datos(){
            this.cargarContratos();
        }
    },
    methods: {
        async anadirContrato(){
            const api = new PwgsApi();
            const body = {"contrato": this.contratoSeleccionado};
            this.contratos = await api.post('companias/'+this.datos.idcompañia+'/contratos', body);
            this.cargarContratos();
        },
        async cargarContratos(){
            const api = new PwgsApi();
            this.contratosDisponibles = await api.get('companias/'+this.datos.idcompañia+'/contratos-disponibles');
            this.contratos = await api.get('companias/'+this.datos.idcompañia+'/contratos');
        },
        async eliminarContrato(idcontrato) {
            const api = new PwgsApi();
            await api.delete('companias/'+this.datos.idcompañia+'/contratos/'+idcontrato);
            this.cargarContratos();
        }
    },
}
</script>